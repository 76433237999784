import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondChanceProgram = () => {
  return (
    <Layout>
      <SEO
        title="Share A Smile Program | Muir Oral, Facial, & Dental Implant Surgery of Walnut Creek"
        description="It is time for a brand new, healthy, beautiful smile at Muir Oral, Facial, & Dental Implant Surgery of Walnut Creek."
      />

      <div className="sas">
        <div className="sas__hero sas__section">
          <img
            className="sas__hero-img--desktop"
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/share-a-smile-desktop-hero-image"
            alt="Share A Smile banner"
          />
          <img
            className="sas__hero-img--mobile"
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/share-a-smile-mobile-hero-image"
            alt="Share A Smile banner"
          />
        </div>

        <div className="sas__body sas__section">
          <div className="sas__container small">
            <h1>Providing a New Smile to a Member of Our Community</h1>

            <p>
              Muir Oral, Facial, & Dental Implant Surgery of Walnut Creek is
              proud to announce its third Share A Smile program. This
              life-changing program provides one deserving recipient who lives
              in or near Walnut Creek with a complete smile makeover free of
              charge.
            </p>

            <p>
              The ideal candidate has multiple missing or failing teeth and
              cannot afford restorative treatment. After the submission period,
              we will select one recipient to receive a free{" "}
              <Link
                to="/full-arch-restoration-walnut-creek-ca/"
                title="Learn more about full-arch-restoration"
              >
                full-arch restoration
              </Link>{" "}
              procedure. We encourage anyone in our community who is dreaming of
              a healthy, beautiful new smile to apply!
            </p>

            <h3>Full-Arch Restoration</h3>
            <p>
              Full-arch restoration is a healthy and effective way to replace
              many missing teeth. The procedure works by securing a full
              prosthesis in the mouth using as few as four dental implants. The
              dental implant posts will permanently hold your new teeth in place
              while also preventing bone loss.{" "}
            </p>
          </div>
        </div>

        {/* <div className="sas__section sas__slider">
          <div className="sas__container small">
            <ImageSlider
              images={[
                "MOFS/DEV/shareasmile-recipient-2022-1",
                "MOFS/DEV/shareasmile-recipient-2022-2",
                "MOFS/DEV/shareasmile-recipient-2022-3",
                "MOFS/DEV/shareasmile-recipient-2022-4",
                "MOFS/DEV/shareasmile-recipient-2022-5",
              ]}
              adaptiveHeight
              useDots
            />
          </div>
        </div> */}

        <div className="sas__section">
          <div className="sas__container small">
            <div className="sas__brackets">
              <div className="sas__about-grid">
                <div>
                  <p className="sas__subheading">How To Apply:</p>
                  <ul>
                    <li>Complete the short application.</li>
                    <li>Upload photos of your smile and teeth.</li>
                    <li>Submit your application by October 6, 2023.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sas__section">
          <div className="sas__container small">
            <p>
              <b>
                Thank you for your interest in the Share A Smile program. The
                application period is now closed. Applicants will be notified
                once the selection process has been completed.
              </b>
            </p>
            <p>
              Stay tuned to our{" "}
              <a
                href="https://www.facebook.com/Muir-Oral-Facial-Dental-Implant-Surgery-108645897698163/"
                title="Follow us on Facebook"
                target="_blank"
              >
                Facebook{" "}
              </a>
              and{" "}
              <a
                href="https://www.instagram.com/muiroralsurgery/"
                title="Follow us on Instagram"
                target="_blank"
              >
                Instagram
              </a>{" "}
              pages for program updates!
            </p>

            <p>
              <i>
                Learn more about{" "}
                <Link
                  to="/dental-implants-walnut-creek-ca/"
                  title="Learn more about dental implants"
                >
                  dental implants
                </Link>{" "}
                and{" "}
                <Link
                  to="/full-arch-restoration-walnut-creek-ca/"
                  title="Learn more about full-arch-restoration"
                >
                  full-arch restoration
                </Link>{" "}
                to see how they can benefit your smile. Whether you need to
                replace one tooth or an entire arch of teeth, our team has a
                solution for you.
              </i>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SecondChanceProgram
